<template>
  <div
    id="successFullSection"
    class="
      d-flex
      flex-column
      justify-content-center
      align-items-center
      successDiv
      pb-5
      pt-5
      mt-5
    "
  >
    <div class="successCircle">
      <img :src="successIcon" alt="" />
    </div>
    <h4>{{ success.headerTitle }}</h4>
    <p class="paragraph mt-3">{{ success.paragraph }}</p>
    <button @click="loadInvoice()" class="btn btnInvoice mt-4">
      {{ success.viewInvoice }} Invoice
    </button>
  </div>
</template>

<script>
import successIcon from "../assets/Icons/img.png";

export default {
  name: "Successful",
  data: function () {
    return {
      successIcon,
      success: this.$store.getters.getSuccess,
    };
  },
  mounted() {
    window.scrollTo(10, 0);
  },
  methods: {
    loadInvoice() {
      const booingID = this.$route.params.bookinID;

      this.$router.push(`/invoice/${this.$route.params.bookinID}`);
    },
  },
};
</script>

<style scoped>
.paragraph {
  text-align: justify;
}
#successFullSection {
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  height: 100%;
}

.btnInvoice {
  background: #000080;
  color: #ffffff;
  width: 130px;
  height: 35px;
  border-radius: 13px;
}

@media all and (max-width: 425px) {
  .btnInvoice {
    width: 130px;
    height: 35px;
    margin-bottom: 5px;
  }

  .successDiv h4 {
    font-size: 18px;
  }
}

.successCircle {
  background: #d1eed2;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.successCircle img {
  width: 24px;
  height: 24px;
  text-align: center;
  font-weight: bold;
}

.successDiv h4 {
  color: #000080;
  margin-top: 20px;
}
</style>
